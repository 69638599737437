import { useCallback, useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core'
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../../Styles/colors';
import { IPaginatedTableProps } from './PaginatedTable.types';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles(() => ({
  root: {
    height: 650,
    width: "100%",
  }
}))


function PaginatedTable(props: IPaginatedTableProps) {
  const classes = useStyles()
  const [t] = useTranslation()


  return (
    <div className={classes.root}>
      <DataGrid 
        onRowClick={props.onRowClick}
        rows={props.rows}
        columns={props.columns}
        style={{fontSize :'12px !important'}}
        pagination
        paginationMode="server"
        filterMode="server"
        page={props.currentPage}
        rowCount={props.totalCount}
        onPageChange={props.onPageChange}
        loading={props.loading}
        disableSelectionOnClick
        onPageSizeChange={props.onPageSizeChange}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 20]}
        localeText={{
          noRowsLabel: t('no_rows')
        }}
      />
    </div>
  );
}

export default PaginatedTable