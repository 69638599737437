import React, { useState, useEffect } from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    dateField: {
        '& input': {
            backgroundColor: '#white',
            borderRadius: '4px',
        },
        '& label': {
            color: '#333',
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
    },
    timeField: {
        width: '150px',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
        },
        '& label': {
            color: '#333',
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '& .MuiPickerPopper-root': {
            backgroundColor: '#white', // Customize the background color here
        },
        '& .MuiPickersPopper-root': {
            '& .MuiPaper-root': {
                backgroundColor: '#white', // Customize the background color here
            },
        },
    },
}));

const CustomDateTimePicker = ({
    label,
    date_value,
    onDateChange,
    readOnlyBidder,
    minimumDate,
}) => {
    const classes = useStyles();
    const [date, setDate] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [minDate, setMinDate] = useState<string>('');

    useEffect(() => {
        if (date_value) {
            const parsedDate = new Date(date_value);
            setDate(parsedDate.toISOString().split('T')[0]); // Date part only
            const hours = parsedDate.getUTCHours().toString().padStart(2, '0');
            const minutes = parsedDate.getUTCMinutes().toString().padStart(2, '0');
            setTime(`${hours}:${minutes}`);
        } else {
            setDate('');
            setTime('');
        }
    }, [date_value]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
        updateDateTime(event.target.value, time);
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = event.target.value;
        setTime(newTime);
        updateDateTime(date, newTime);
    };

    const updateDateTime = (newDate: string, newTime: string) => {
        if (newDate && newTime) {
            const [hours, minutes] = newTime.split(':').map(Number);
            const combinedDateTime = new Date(newDate);
            combinedDateTime.setUTCHours(hours, minutes, 0, 0);
            const isoString = combinedDateTime.toISOString();
            onDateChange(isoString); // Pass ISO string to parent component
        }
    };
    useEffect(() => {
        if (minimumDate != '') {
            const minna = moment(minimumDate).utc().format('YYYY-MM-DD').toString()
            setMinDate(minna)

        }
    }, [minimumDate])

    return (
        <Grid item container spacing={2} direction="row">
            <Grid item xs={6} sm={6} md={4}>
                <TextField
                    fullWidth
                    id="date"
                    label="Date"
                    type="date"
                    variant='outlined'
                    value={date}
                    onChange={handleDateChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: minDate } }}
                    readOnly={readOnlyBidder}
                    className={classes.dateField}
                />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
                <TextField
                    id="time"
                    label="Time"
                    fullWidth
                    variant='outlined'

                    type="time"
                    value={time}
                    onChange={handleTimeChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }} // 5 min
                    className={classes.timeField}
                    readOnly={readOnlyBidder}
                />
            </Grid>
        </Grid>
    );
};

export default CustomDateTimePicker;
