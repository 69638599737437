import { WebStorage } from "../../Utilities/WebStorage"
import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()

export class OCRlogs {

    getLogs(currentPage: number, countPerPage: number, dateFilter: any) {
        return axios.get(`${baseUrl}/job/reports/ocr`, {
            params: {
                currentPage,
                countPerPage,
                dateFilter
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getHistory(filename) {
        return axios.get(`${baseUrl}/job/reports/ocr/history`, {
            params: {
                filename
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
}