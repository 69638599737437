// import 'moment-timezone';
// import moment from 'moment';
const moment = require('moment');

export const formatDateTime = (dateTime: any) => moment(dateTime).utc().toString();
export const convertToUTC = (date) => {
  if (moment(date).isValid()) {
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utcDate;
  }
  return null
};
export const formatDate = (dateTime: any, format: string = 'ddd, MMM Do YYYY') =>
  moment(dateTime).format(format);

export const formatShortDate = (dateTime: any) => moment(dateTime).format('DD-MMM');

export const formatTime = (dateTime: any) => moment(dateTime).format("hh:mm:ss a")

export const formatDateTimeDisplay = (dateTime: any) => {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).utc().format('DD/MM/YYYY hh:mm A').toString()
  } else {
    return 'Pending Date'
  }
};

export const formatInputDateTime = (dateTime: any) => {
  return moment(dateTime).utc().format('dd/MM/yyyy HH:mm')
};

export const formatDateTimeDisplayDD = (dateTime: any) => {
  if (moment(dateTime).isValid()) {
    return moment(dateTime).utc().format('DD/MM/YYYY').toString()
  } else {
    return 'Pending Date'
  }
};

export const formatDisplayOffset = (dateTime: any) => {
  const tempDate = new Date(dateTime)
  return moment(new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000)).format('dd/MM/yyyy HH:mm A').toString()
}
export const formatSaveOffset = (dateTime: any) => {
  if (moment(dateTime).isValid()) {
    return new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * 60000)

  } else {
    return ''
  }
}

export const formatReadOffset = (tempDate: any) => {
  if (moment(tempDate).isValid()) {
    // const tempDate = new Date(dateTime)
    return new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000)
  } else {
    return ''
  }

}

export const formatTimeOnly = (dateTime: any) => moment(dateTime).format("hh:mm")