import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Address } from '../../../../Api/Address/Address';
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import CountrySelect from '../../../Dialogs/CreateAddressDialog/CountrySelect';
import { useTranslation } from 'react-i18next';
import { COUNTRIES, ADDRESS_GROUPS, NZ_STATES, AU_STATES, STATES_BY_COUNTRY } from "../../../../Types/Constants";
import AddIcon from '@material-ui/icons/Add';

const addressApi = new Address();




const AddressSelector = ({
    addresses,
    companyId,
    disabled,
    isEditMode,
    onEditClick,
    handleAddressSelected,
    handleSelectedGroup,
    handleSelectedState,
    handleSelectedCountry,
    defaultCountry,
    defaultState,
    defaultGroupType,
    defaultCompany,
    refresh,
    addressEvent
}) => {
    const { t } = useTranslation('CreateNewJob');
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry || '');
    const [selectedState, setSelectedState] = useState(defaultState || '');
    const [selectedGroupType, setSelectedGroupType] = useState(defaultGroupType || '');
    const [selectedCompany, setSelectedCompany] = useState(defaultCompany || '');
    // const [addresses, setAddresses] = useState<IAddress[]>([]);
    const dispatch = useDispatch();



    const countriesArray = useMemo(
        () => Array.from(new Set(addresses.map((item) => item.country).filter((item) => item !== '' && item !== null))),
        [addresses]
    );

    const statesArray = useMemo(() => {
        if (!selectedCountry || !STATES_BY_COUNTRY[selectedCountry]) {
            return [];
        }

        const countryStates = new Set(Object.values(STATES_BY_COUNTRY[selectedCountry]));

        return Array.from(new Set(
            addresses
                .filter((item) => item.country === selectedCountry && countryStates.has(item.state))
                .map((item) => item.state)
        ));
    }, [addresses, selectedCountry]);

    const groupTypesArray = useMemo(
        () => Array.from(new Set(
            addresses
                .filter((item) => item.state === selectedState)
                .map((item) => item.address_group)
                .filter((item) => item !== '' && item !== null)
        )),
        [addresses, selectedState]
    );

    const companiesArray = useMemo(
        () => Array.from(new Set(
            addresses
                .filter((item) => item.state === selectedState && item.address_group === selectedGroupType)
                .map((item) => ({
                    id: item.id,
                    name: item.company_name || '',
                    street_number: item.street_number || '',
                    city: item.city || '',
                    state: item.state || '',
                }))
        )),
        [addresses, selectedState, selectedGroupType]
    );
    useEffect(() => {
        setSelectedCountry(selectedCountry);
        setSelectedGroupType('');
        setSelectedCompany('');
    }, [selectedCountry, statesArray, defaultState, selectedCountry]);

    useEffect(() => {
        setSelectedState((prevState) => (statesArray.includes(defaultState) ? defaultState : ''));
        setSelectedGroupType('');
        setSelectedCompany('');
    }, [selectedCountry, statesArray, defaultState]);

    useEffect(() => {
        setSelectedGroupType((prevGroupType) => (groupTypesArray.includes(defaultGroupType) ? defaultGroupType : ''));
        setSelectedCompany('');
    }, [selectedState, groupTypesArray, defaultGroupType]);

    useEffect(() => {
        setSelectedCompany((prevCompany) =>
            companiesArray.some((company: any) => company.id == defaultCompany) ? defaultCompany : ''
        );
    }, [selectedGroupType, companiesArray, defaultCompany]);

    const formatAddress = useCallback(
        (address) => `${address.street_number} ${address.city} ${address.state}`,
        []
    );

    return (
        <Grid item container direction='row' spacing={2}>
            <Grid item xs={12} sm={12} md={4}>

                <CountrySelect
                    value={selectedCountry}
                    onChange={(e) => {
                        setSelectedCountry(e.target.value)
                        handleSelectedCountry(e)
                    }}
                    disabled={disabled}
                    isPreview={disabled}
                    isEditable={disabled}
                    t={t}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>

                <FormControl fullWidth variant="outlined" disabled={disabled || !selectedCountry}>
                    <InputLabel>{t('state')}</InputLabel>
                    <Select
                        value={selectedState}
                        onChange={(e) => {
                            setSelectedState(e.target.value);
                            handleSelectedState(e);
                        }}
                        label={t('state')}
                    >
                        <MenuItem value="">
                            <em>Select {t('state')}</em>
                        </MenuItem>
                        {statesArray.map((state: any) => (
                            <MenuItem key={state} value={state}>
                                {t(state)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth variant="outlined" disabled={disabled || !selectedState}>
                    <InputLabel>{t('pickup_select_group')}</InputLabel>
                    <Select
                        value={selectedGroupType}
                        onChange={(e) => {
                            setSelectedGroupType(e.target.value);
                            handleSelectedGroup(e);
                        }}
                        label={t('pickup_select_group')}
                    >
                        <MenuItem value="">
                            <em>Select {t('pickup_select_group')}</em>
                        </MenuItem>
                        {groupTypesArray.map((type: any) => (
                            <MenuItem key={type} value={type}>
                                {t(type)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} container spacing={1} sm={12} md={12}>

                <Grid item xs={10} sm={10} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={disabled || !selectedGroupType}>
                        <InputLabel>{t('pickup_select_address')}</InputLabel>
                        <Select
                            value={selectedCompany}
                            onChange={(e) => {
                                setSelectedCompany(e.target.value);
                                handleAddressSelected(e);
                            }}
                            label={t('pickup_select_address')}
                        >
                            <MenuItem value="">
                                <em>Select {t('pickup_select_address')}</em>
                            </MenuItem>
                            {companiesArray.map((company: any) => (
                                <MenuItem key={company.id} value={company.id}>
                                    <div style={{ color: 'gray' }}>{company.name} &nbsp;</div> {formatAddress(company)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid alignContent='center' item xs={2} sm={2} md={1} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            onEditClick(selectedCompany);
                        }}
                        disabled={!selectedCompany}
                    >
                        {t('Edit')}
                    </Button>

                </Grid>
                <Grid alignContent='center' item xs={2} sm={2} md={2} lg={2}>
                    <Button
                        fullWidth
                        onClick={addressEvent}
                        startIcon={<AddIcon />}
                        color="primary"
                        variant="contained"
                    >
                        {t('add_new_address')}
                    </Button>

                </Grid>

            </Grid>
        </Grid>
    );
};

export default AddressSelector;
