import { Hidden, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IOcrData } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { formatDateTimeDisplay } from '../../../../Utilities/DateTime'
export const getMpdReportColumns = (t: TFunction, showType: string): GridColumns => {
    return [
        {
            field: 'reference',
            headerName: t(`Columns:reference`),
            width: 150
        },
        {
            field: 'customer_reference',
            headerName: t(`Columns:customer_reference`),
            width: 200
        },
        {
            field: 'pick_up_address',
            headerName: t(`pick_up_city`),
            width: 200,
            renderCell: (params) => <Typography>{(params.row as IOcrData).pickup_add ? (params.row as IOcrData).pickup_add["city"] : ''}</Typography>,
        },
        {
            field: 'pick_up_date',
            headerName: t(`pick_up_date_time`),
            width: 170,
            renderCell: (params) => <Typography>{(params.row as IOcrData).pickup_add ? formatDateTimeDisplay((params.row as IOcrData).pick_up_date) : ''}</Typography>,
        },
        {
            field: 'quantity',
            headerName: t(`quantity`),
            align:'right',

            width: 150
        },
        {
            field: 'weight',
            headerName: t(`weight`),
            align:'right',
            width: 150
        },
        {
            field: 'delivery_address',
            headerName: t(`delivery_address_city`),
            width: 200,
            renderCell: (params) => <Typography>{(params.row as IOcrData).delivery_add ? (params.row as IOcrData).delivery_add["city"] : ''}</Typography>,
        },
        {
            field: 'delivery_date',
            headerName: t(`delivery_date`),
            width: 170,
            renderCell: (params) => <Typography>{(params.row as IOcrData).delivery_date ? formatDateTimeDisplay((params.row as IOcrData).delivery_date) : ''}</Typography>,
        },
        {
            field: 'filename',
            headerName: t(`filename`),
            width: 150,
            hide: showType == 'filter',

        }, {
            field: 'remarks',
            resizable: true,
            headerName: t(`remarks`),
            width: 350
        },
        {
            field: 'status',
            headerName: t(`Columns:status`),
            hide: showType == 'filter',

            renderCell: (params) => <Typography>{t((params.row as IOcrData).status)}</Typography>,
            width: 150
        },
        {
            field: 'date_processed',
            headerName: t(`date_processed`),
            hide: showType != 'filter',
            width: 200,
            renderCell: (params) => <Typography>{(params.row as IOcrData).date_processed ? formatDateTimeDisplay((params.row as IOcrData).date_processed) : ''}</Typography>,
        },
    ]
}